.App {
  height: "100vh";
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.img { 
  width: 250px;
  height: 250px;
  object-fit: cover;
}

.img_homepage{
  width: 100vw;
  height: 500px;
  object-fit: cover;
  min-width: 300px;
}

.img_instructor{
  width:430px;
}
.img_business{
  width:400px;
  
}
@media only screen and (max-width: 600px) {
  .img_instructor {
    width: 350px;
  }
  .img_business{
    width: 300px;
  }
}


.video_homepage{
  width: 100vw;
  height: 500px;
  object-fit: cover;
}

.logo_icon{
  width: 100px;
}

.img_contactUs{
  width: 400px;
  height: 550px;
  object-fit: fill;
  /* filter: grayscale(100%); */
}

.img_course_banner{
  width:100%;
  max-width: 1000px;
  height: 250px; /* Fixed height of 250px */
  border-radius: 2px;
  object-fit: fill;
  
}


@media (max-width: 600px) {
  .img_course_banner {
    min-width: 250px;
    /* max-width: calc(2000px/2); */
  }
}


.img_card{
  width: 100%;
  height:100%;
  object-fit: fill;
  border: 1px solid #dadada;
  border-radius:5px;
}

.slider {
  display: flex;
  transition: transform 0.5s ease-in-out;
}

