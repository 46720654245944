* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Gerbera',sans-serif;
  /* outline: 1px solid red;  */ /* Add a visible border to all elements */
  max-width: 100%;
}

@font-face {
  font-family: 'Gerbera';
  font-display: swap;
  src: local('Gerbera'), url('./fonts/Gerbera-Bold.otf') format('opentype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Gerbera';
  font-display: swap;
  src: local('Gerbera'), url('./fonts/Gerbera-Regular.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Gerbera';
  font-display: swap;
  src: local('Gerbera'), url('./fonts/Gerbera-Light.otf') format('opentype');
  font-weight: 300;
  font-style: normal;
}

html,body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
#root {
  width: 100%;
  height: 100%;
}